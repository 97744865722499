import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import useTotalBlocks from '../expenseAndBills/bills/BillList/total.hook';
import configObject from '../../../config';
import LessText from '../../styled/CommonComponents/LessText';
import BudgetUpdate from '../../budgetCostAnalysis/BudgetUpdate';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    editIconSty: {
        fontSize: "25px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    invoiceEditBody: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 25px 100px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px 50px",
        }
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        paddingRight: "20px"
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    nextIcon: {
        transform: 'rotate(180deg)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    emptyCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            height: "250px",
            width: "auto",
            marginBottom: "10px"
        },
        "& p": {
            fontSize: "14px",
            color: "gray"
        }
    },
    sectionTop: {
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        marginTop: "30px",
        padding: "5px",
        "& h3": {
            fontSize: "21px",
            fontWeight: "600",
            color: "#193B56"
        },
        [theme.breakpoints.down('sm')]: {
            "& h3": {
                fontSize: "18px",
                fontWeight: "600",
            },
        }
    },
    showSecondPartyInfoCont: {
        width: "260px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "13px",
            color: "gray"
        }
    },
    showSecondPartyImgCont: {
        width: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    showSecondPartyCont: {
        width: "320px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "7px 0px"
    },
    lateFeesSty: {
        display: "flex",
        paddingLeft: "8px",
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            marginLeft: "10px",
            fontSize: "12px",
            color: "gray",
            paddingTop: "7px"
        }
    },
    memoCont: {
        paddingLeft: "10px",
        "& p": {
            fontSize: "14px",
            fontWeight: "500"
        }
    },
    mainInputCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "20px",
        borderRadius: "10px",
        border: "1px solid #dcdcdc",
        marginBottom: "20px",
        [theme.breakpoints.down('md')]: {
            padding: "10px",
        }
    },
    productsCont: {
        width: "200px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "450"
        },
        [theme.breakpoints.down('md')]: {
            width: "31%",
            marginBottom: "15px"
        },
        [theme.breakpoints.down('xs')]: {
            width: "48%",
            marginBottom: "10px",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px",
                fontWeight: "400"
            },
        },
    },
    chartAccCont: {
        width: "200px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "450"
        },
        [theme.breakpoints.down('md')]: {
            width: "31%",
            marginBottom: "15px"
        },
        [theme.breakpoints.down('xs')]: {
            width: "48%",
            marginBottom: "10px",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px",
                fontWeight: "400"
            },
        },
    },
    qtyCont: {
        width: "150px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "450"
        },
        [theme.breakpoints.down('md')]: {
            width: "31%",
            marginBottom: "15px"
        },
        [theme.breakpoints.down('xs')]: {
            width: "48%",
            marginBottom: "10px",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px",
                fontWeight: "400"
            },
        },
    },
    rateSty: {
        width: "calc((100% - 720px) / 2)",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "450"
        },
        [theme.breakpoints.down('md')]: {
            width: "31%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "48%",
            marginBottom: "10px",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px",
                fontWeight: "400"
            },
        },
    },
    taxSty: {
        width: "100px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "450"
        },
        [theme.breakpoints.down('md')]: {
            width: "31%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "48%",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px",
                fontWeight: "400"
            },
        },
    },
    totalSty: {
        width: "calc((100% - 720px) / 2)",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "450"
        },
        [theme.breakpoints.down('md')]: {
            width: "31%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "48%",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px",
                fontWeight: "400"
            },
        },
    },
    descSty: {
        width: "100%",
        marginTop: "15px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "450"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "10px",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px",
                fontWeight: "400"
            },
        },
    },
    totalTableCont: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        margin: "40px 0px 20px",
        paddingRight: "20px",
        [theme.breakpoints.down('xs')]: {
            padding: "0px",
        }
    },
    totalTableBody: {
        width: "400px",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    tableRow: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "7px 0px",
        [theme.breakpoints.down('xs')]: {
            padding: "5px 0px",
        }
    },
    tableLable: {
        width: "60%",
        fontSize: "15px",
        fontWeight: "500",
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "400"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px",
            "& h3": {
                fontSize: "14px",
            },
            "& p": {
                fontSize: "10px",
            },
        }
    },
    tableValue: {
        width: "40%",
        fontSize: "14px",
        fontWeight: "450",
        textAlign: "right",
        [theme.breakpoints.down('xs')]: {
            fontSize: "12px",
            fontWeight: "400",
        }
    },
    journalEntryCont: {
        widht: "100%",
        border: "1px solid #E1E2E5",
        padding: "30px 20px",
        marginTop: "50px",
        marginBottom: "50px"
    },
    chartAccountTitle: {
        fontSize: "18px",
        fontWeight: "550",
        marginBottom: "30px",
        display: "flex",
        alignItems: "center",
    },
}));

export default function InvoiceOtherSettingView({ tx, setTx, walletId, updateApi }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { SubTotalView, TaxView, DiscountView, GrandTotalView, grandTotal } = useTotalBlocks({ tx, updateApi: null });

    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));
    const [projectProfileId, setProjectProfileId] = useState(null)
    const [selectedBudgetId, setSelectedBudgetId] = useState([])
    const [projectBlockId, setProjectBlockId] = useState(null)
    const [projectId, setProjectId] = useState(null)
    const [projectParentModel, setProjectParentModel] = useState(null)
    const [projectParentId, setProjectParentId] = useState(null)

    useEffect(() => {
        setProjectProfileId(tx?.firstParty?._id || null)
        setSelectedBudgetId(tx?.selectedBudgetIds || [])
        setProjectBlockId(null)
        setProjectId(tx?.data?.workOrder?.project)
        setProjectParentModel(tx?.data?.workOrder?.projectParentModel)
        setProjectParentId(tx?.data?.workOrder?.projectParent)
    }, [tx])

    const payNow = () => {
        // const basicUrl = configObject?.BASE_URL;
        // const goUrl = basicUrl + "payment/" + tx?._id
        // window.open(goUrl);
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const addNewSelectedItems = async (items) => {
        const updatedTx = {
            ...tx,
            selectedBudgetIds: items
        }
        setTx(updatedTx)
        await updateApi({
            _id: tx?._id,
            selectedBudgetIds: items
        })
    }

    return (
        <div className={classes.root}>
            <div className={classes.topBar} >
                <div className={classes.barLeft} >
                    <IconButton className={classes.iconButtonStyle} onClick={() => { history.goBack() }}>
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>
                    <Typography className={classes.headerTitle} >
                        {tx?.type === "Bill" ? (walletId === tx?.firstPartyWallet?._id ? (<>Expense</>) : (<>Receipt</>)) : (<></>)}
                        {tx?.type === "Invoice" ? (walletId === tx?.firstPartyWallet?._id ? (<>Invoice</>) : (<>Bill</>)) : (<></>)}
                        <span style={{ marginLeft: "10px" }} >#{tx?.txNo}</span>
                    </Typography>
                </div>
                <div className={classes.barRight} >
                    {walletId === tx?.firstPartyWallet?._id && (
                        <BudgetUpdate
                            selectedBudgetId={selectedBudgetId}
                            setSelectedBudgetId={setSelectedBudgetId}
                            projectProfileId={projectProfileId}
                            projectBlockId={projectBlockId}
                            addNewSelectedItems={addNewSelectedItems}
                            workspaceType={"Transaction"}
                            workspaceId={tx?._id}
                            budgetUpdateStatus={"Paid"}
                            projectId={projectId}
                            projectParentId={projectParentId}
                            projectParentModel={projectParentModel}
                            parentTotalAmount={grandTotal}
                        />
                    )}
                    {tx?.status === "Partially Paid" || tx?.status === "Processing" || tx?.status === "Paid" ? (<div></div>) : (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "10px" }}
                            onClick={() => { payNow() }}
                            size={isExSmall ? "small" : "medium"}
                        >
                            Pay Now
                        </Button>
                    )}
                </div>
            </div>
            <div className={classes.invoiceEditBody} >
                <div className={classes.sectionTop} style={{ marginTop: "10px" }} >
                    <h3>
                        {tx?.type === "Bill" ? (walletId === tx?.firstPartyWallet?._id ? (<>Vendor Details</>) : (<>Customer Details</>)) : (<></>)}
                        {tx?.type === "Invoice" ? (walletId === tx?.firstPartyWallet?._id ? (<>Customer Details</>) : (<>Vendor Details</>)) : (<></>)}
                    </h3>
                </div>
                <div className={classes.showSecondPartyCont} >
                    <div className={classes.showSecondPartyImgCont} >
                        <Avatar
                            alt={walletId === tx?.firstPartyWallet?._id ? tx?.secondParty?.parent?.displayName : tx?.firstParty?.parent?.displayName}
                            src={walletId === tx?.firstPartyWallet?._id ? tx?.secondParty?.parent?.displayPicture?.url : tx?.firstParty?.parent?.displayPicture?.url}
                        />
                    </div>
                    <div className={classes.showSecondPartyInfoCont} >
                        <h3>
                            <LessText
                                limit={20}
                                string={walletId === tx?.firstPartyWallet?._id ? tx?.secondParty?.parent?.displayName : tx?.firstParty?.parent?.displayName}
                            />
                        </h3>
                        <p>{walletId === tx?.firstPartyWallet?._id ? tx?.secondParty?.parent?.email : tx?.firstParty?.parent?.email}</p>
                    </div>
                </div>



                {tx?.lateFeeAmount && parseInt(tx?.lateFeeAmount) > 0 && (<>
                    <div className={classes.sectionTop} >
                        <h3>Due Date & Late Fees</h3>
                    </div>
                    <div className={classes.lateFeesSty} >
                        <h3>₹{numberWithCommas(tx?.lateFeeAmount)}</h3>
                        <p>Due {moment(tx?.dueDate).format("MMMM Do YYYY")}</p>
                    </div>
                </>)}



                {tx?.memo && (<>
                    <div className={classes.sectionTop} >
                        <h3>Note</h3>
                    </div>
                    <div className={classes.memoCont} >
                        <p>{tx?.memo}</p>
                    </div>
                </>)}



                <div className={classes.sectionTop} >
                    <h3>Items</h3>
                </div>
                <div>
                    {tx?.billList?.items.length > 0 && tx?.billList?.items.map((item, i) => (
                        <div className={classes.mainInputCont} key={i} >
                            {/* Product & Services */}
                            <div className={classes.productsCont} >
                                <h3>Product/Service</h3>
                                <p>{item?.name}</p>
                            </div>

                            {/* Category(Chart Account) */}
                            <div className={classes.chartAccCont} >
                                <h3>Chart Account</h3>
                                <p>{walletId === tx?.firstPartyWallet?._id ? item?.chartAccount?.name : "XXXXXXXXXX"}</p>
                            </div>

                            {/* Quantity */}
                            <div className={classes.qtyCont} >
                                <h3>Quantity (units)</h3>
                                <p>{numberWithCommas(item?.qTy)}</p>
                            </div>

                            {/* Rate */}
                            <div className={classes.rateSty} >
                                <h3>Rate Per Unit</h3>
                                <p>₹ {numberWithCommas(item?.rate)}</p>
                            </div>

                            {/* total value */}
                            <div className={classes.totalSty} >
                                <h3>Total Price</h3>
                                <p>₹ {numberWithCommas(item?.rate * item?.qTy)}</p>
                            </div>

                            {/* Tax */}
                            <div className={classes.taxSty} >
                                <h3>Tax</h3>
                                <p>{item?.tax ? (<span style={{ color: "green" }} >Applied</span>) : (<span style={{ color: "red" }} >Not Applied</span>)}</p>
                            </div>

                            {/* Description */}
                            <div className={classes.descSty} >
                                <h3>Description</h3>
                                <p>{item?.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classes.totalTableCont} >
                    <div className={classes.totalTableBody} >
                        <div className={classes.tableRow} >
                            <div className={classes.tableLable} >Sub Total</div>
                            <div className={classes.tableValue} >{SubTotalView}</div>
                        </div>

                        {tx?.type === "Invoice" && (<>
                            <div style={tx?.billList?.orderReverse ? { width: "100%", display: "flex", flexDirection: "column" } : { width: "100%", display: "flex", flexDirection: "column-reverse" }} >
                                <div className={classes.tableRow} >
                                    <div className={classes.tableLable} >
                                        <h3>
                                            {walletId === tx?.firstPartyWallet?._id ?
                                                (<>Tax</>)
                                                :
                                                (<>{tx?.billList?.tax && tx?.billList?.tax?.enabled ? (<>
                                                    Tax - ({`${tx?.billList?.tax?.type === "%" ? `${tx?.billList?.tax?.percent}%` : `₹${tx?.billList?.tax?.amount}`}`})
                                                </>) : (<>Tax</>)}</>)
                                            }
                                        </h3>
                                        {tx?.billList?.taxRelationModel?.name && tx?.billList?.tax && tx?.billList?.tax?.enabled && walletId === tx?.firstPartyWallet?._id && (
                                            <p>{`${tx?.billList?.taxRelationModel?.name} - (${tx?.billList?.tax?.type === "%" ? `${tx?.billList?.tax?.percent}%` : `₹${tx?.billList?.tax?.amount}`})`}</p>
                                        )}
                                    </div>
                                    <div className={classes.tableValue} >{TaxView}</div>
                                </div>
                                <div className={classes.tableRow} >
                                    <div className={classes.tableLable} >
                                        <h3>
                                            {walletId === tx?.firstPartyWallet?._id ?
                                                (<>Discount</>)
                                                :
                                                (<>{tx?.billList?.discount && tx?.billList?.discount?.enabled ? (<>
                                                    Discount - ({`${tx?.billList?.discount?.type === "%" ? `${tx?.billList?.discount?.percent}%` : `₹${tx?.billList?.discount?.amount}`}`})
                                                </>) : (<>Discount</>)}</>)
                                            }
                                        </h3>
                                        {tx?.billList?.discountRelationModel && tx?.billList?.discount && tx?.billList?.discount?.enabled && walletId === tx?.firstPartyWallet?._id && (
                                            <p>{`${tx?.billList?.discountRelationModel?.name} - (${tx?.billList?.discount?.type === "%" ? `${tx?.billList?.discount?.percent}%` : `₹${tx?.billList?.discount?.amount}`})`}</p>
                                        )}
                                    </div>
                                    <div className={classes.tableValue} >{DiscountView}</div>
                                </div>
                            </div>
                            <div className={classes.tableRow} >
                                <div className={classes.tableLable} >Grand Total</div>
                                <div className={classes.tableValue} >{GrandTotalView}</div>
                            </div>
                            <div className={classes.tableRow} >
                                <div className={classes.tableLable} >Late Fees</div>
                                <div className={classes.tableValue} >₹{tx?.lateFeeAmount}</div>
                            </div>
                        </>)}
                    </div>
                </div>



                {walletId === tx?.firstPartyWallet._id && (<>
                    <div className={classes.sectionTop} >
                        <h3>Bank Chart Account</h3>
                    </div>
                    <div className={classes.memoCont} >
                        <p>{tx?.BankChartAccount?.name}</p>
                    </div>
                </>)}



                <div className={classes.sectionTop} >
                    <h3>Transaction Status</h3>
                </div>
                <div className={classes.memoCont} >
                    <p>{tx?.status}</p>
                </div>
            </div>
        </div>
    );
}