import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import BillOtherSettings from './BillOtherSettings';
import BillPaymentSettings from './BillPaymentSettings';
import BillOtherSettingsView from './BillOtherSettingsView';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  page1: {
    width: "100%",
    height: "100%",
  },
  page2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    zIndex: "1000",
    backgroundColor: "white"
  },
  loaderCont: {
    position: "fixed",
    top: "0px",
    right: "0px",
    width: "100vw",
    height: "100vh",
    zIndex: "100",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  wrongCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.fail
  }
}));

export default function BillEdit({
  tx, setTx, chartAccounts, setChartAccounts, offerings,
  setOfferings, txNosArr, setTxNosArr, maxNo, setMaxNo,
  updateApi, walletId, bankChartAccounts, setBankChartAccounts,
  incomeChartAccounts, setIncomeChartAccounts, expenseChartAccounts,
  setExpenseChartAccounts, needToMakeDisable, activeStage,
  needToMakeStatusDisable
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const [openPayment, setOpenPayment] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);


  return (
    <div className={classes.root}>
      <div className={classes.page1} >
        {(walletId === tx?.firstPartyWallet._id && tx.status !== "Paid") ?
          (
            <BillOtherSettings
              tx={tx}
              setTx={setTx}
              chartAccounts={chartAccounts}
              setChartAccounts={setChartAccounts}
              offerings={offerings}
              setOfferings={setOfferings}
              txNosArr={txNosArr}
              setTxNosArr={setTxNosArr}
              maxNo={maxNo}
              setMaxNo={setMaxNo}
              incomeChartAccounts={incomeChartAccounts}
              setIncomeChartAccounts={setIncomeChartAccounts}
              expenseChartAccounts={expenseChartAccounts}
              setExpenseChartAccounts={setExpenseChartAccounts}
              updateApi={updateApi}
              walletId={walletId}
              setOpenPayment={setOpenPayment}
              setLoadingBool={setLoadingBool}
              needToMakeDisable={needToMakeDisable}
              activeStage={activeStage}
              needToMakeStatusDisable={needToMakeStatusDisable}
            />
          ) : (
            <BillOtherSettingsView
              tx={tx}
              walletId={walletId}
            />
          )}
      </div>
      <Slide direction="left" in={openPayment} mountOnEnter unmountOnExit>
        <div className={classes.page2} >
          {(walletId === tx?.firstPartyWallet._id && tx.status !== "Paid") ? (
            <BillPaymentSettings
              tx={tx}
              setTx={setTx}
              txNosArr={txNosArr}
              setTxNosArr={setTxNosArr}
              maxNo={maxNo}
              setMaxNo={setMaxNo}
              updateApi={updateApi}
              walletId={walletId}
              setOpenPayment={setOpenPayment}
              setLoadingBool={setLoadingBool}
              loadingBool={loadingBool}
              bankChartAccounts={bankChartAccounts}
              setBankChartAccounts={setBankChartAccounts}
              openPayment={openPayment}
              needToMakeDisable={needToMakeDisable}
              activeStage={activeStage}
              needToMakeStatusDisable={needToMakeStatusDisable}
            />
          ) : (
            <BillOtherSettingsView
              tx={tx}
              walletId={walletId}
            />
          )}
        </div>
      </Slide>
      {loadingBool &&
        <div className={classes.loaderCont} >
          <CircularProgress
            size={60}
            thickness={3}
            style={{ color: 'rgb(92, 144, 242)' }}
          />
        </div>}
    </div>
  );
}