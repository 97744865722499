import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, useMediaQuery } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TxSecondParty from '../../../commonComponent/transaction/secondParty';
import TxBillListEdit from '../../../commonComponent/transaction/tx.bill.list.edit';
import DialogBillNo from '../../../expenseAndBills/bills/DialogBillNo';
import { updateTxTemplate } from '../../../commonComponent/transaction/api';
import AttachmentsHandler from '../../../../styled/CommonComponents/Attachments.Handler';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    editIconSty: {
        fontSize: "25px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    billEditBody: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 25px 100px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px 50px",
        }
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        paddingRight: "20px"
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    nextIcon: {
        transform: 'rotate(180deg)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    emptyCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            height: "250px",
            width: "auto",
            marginBottom: "10px"
        },
        "& p": {
            fontSize: "14px",
            color: "gray"
        }
    }
}));

export default function BillTemplateOtherSetting({
    txTemplate, setTxTemplate, walletId, chartAccounts,
    setChartAccounts, offerings, setOfferings, templateNosArr,
    setTemplateNosArr, maxNo, setMaxNo, openPayment, setOpenPayment,
    setLoadingBool, needToMakeDisable
}) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const { createdFileIds } = useSelector((state) => state.file);

    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

    const [open, setOpen] = useState(false);
    const [showGL, setShowGL] = useState(txTemplate?.lateFeeApplicable || false);

    return (<>
        <div className={classes.root}>
            <div className={classes.topBar} >
                <div className={classes.barLeft} >
                    <IconButton
                        className={classes.iconButtonStyle}
                        onClick={() => { history.goBack() }}
                    >
                        <KeyboardBackspaceIcon
                            className={classes.backArrow}
                        />
                    </IconButton>
                    <Typography className={classes.headerTitle} >
                        <span style={{ marginLeft: "10px" }} >Expense Generator #{txTemplate?.txNo}</span>
                    </Typography>
                    {!needToMakeDisable && (<>
                        <IconButton
                            color="primary"
                            onClick={() => { setOpen(true) }}
                        >
                            <EditIcon
                                className={classes.editIconSty}
                            />
                        </IconButton>
                        <DialogBillNo
                            open={open}
                            handleClose={() => { setOpen(false); }}
                            tx={txTemplate}
                            setTx={setTxTemplate}
                            type={"BillTemplate"}
                            maxNo={maxNo}
                            setMaxNo={setMaxNo}
                            walletId={walletId}
                            setLoadingBool={setLoadingBool}
                            txNos={templateNosArr}
                        />
                    </>)}
                </div>
                <div className={classes.barRight} >
                    {txTemplate?.secondParty ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { setOpenPayment(true) }}
                            size={isExSmall ? "small" : "medium"}
                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            size={isExSmall ? "small" : "medium"}
                            disabled
                        >
                            Next
                        </Button>
                    )}
                </div>
            </div>
            <div className={classes.billEditBody} >
                <TxSecondParty
                    tx={txTemplate}
                    setTx={setTxTemplate}
                    updateApi={updateTxTemplate}
                    relationType={"Customer"}
                    walletId={walletId}
                    needToMakeDisable={needToMakeDisable}
                    isTemplate={true}
                    setLoadingBool={setLoadingBool}
                />
                <TxBillListEdit
                    tx={txTemplate}
                    setTx={setTxTemplate}
                    updateApi={updateTxTemplate}
                    showGL={showGL}
                    setLoadingBool={setLoadingBool}
                    chartAccounts={chartAccounts}
                    setChartAccounts={setChartAccounts}
                    offerings={offerings}
                    needToMakeDisable={needToMakeDisable}
                    setOfferings={setOfferings}
                />
                {txTemplate?._id && (
                    <AttachmentsHandler
                        parentData={txTemplate}
                        setParentData={setTxTemplate}
                        parentModel={"TxTemplate"}
                        setLoadingBool={setLoadingBool}
                        needToMakeDisable={needToMakeDisable}
                    />
                )}
            </div>
        </div>
    </>);
}