import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { updateTxTemplate, updateTxWithPopulatedData } from "./api";
import _ from "lodash";
import PaginatedEntityDropdown from "../../../styled/CommonComponents/PaginatedEntityDropdown.jsx";

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    borderBottom: "1px solid #d0cfcf",
    marginBottom: "15px",
    padding: "5px",
    "& h3": {
      fontSize: "21px",
      fontWeight: "600",
      color: "#193B56",
    },
    [theme.breakpoints.down("sm")]: {
      "& h3": {
        fontSize: "18px",
        fontWeight: "600",
      },
    },
  },
  showSecondPartyCont: {
    width: "400px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7px 0px",
  },
}));

export default function TxSecondParty({
  tx,
  setTx,
  setLoadingBool,
  isTemplate = false,
  needToMakeDisable,
  relationType,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    if (tx?.secondParty) {
      setSelectedProfile({
        value: tx?.secondParty?._id,
        label: tx?.secondParty?.parent?.displayName,
        data: tx?.secondParty,
      });
    }
  }, [tx?.secondParty]);

  const updateSecondParty = async (profileValue) => {
    if (profileValue?.data?._id) {
      setLoadingBool(true);
      if (isTemplate) {
        await updateTxTemplate({
          _id: tx?._id,
          secondParty: profileValue?.data?._id,
          secondPartyWallet: profileValue?.data?.parent?.wallet,
        })
          .then((data) => {
            setTx(data);
            setLoadingBool(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await updateTxWithPopulatedData({
          _id: tx?._id,
          secondParty: profileValue?.data?._id,
          secondPartyWallet: profileValue?.data?.parent?.wallet,
        })
          .then((data) => {
            setTx(data);
            setLoadingBool(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <>
      <div className={classes.sectionTop} style={{ marginTop: "10px" }}>
        <h3>{tx?.type === "Bill" ? "Vendor Details" : "Customer Details"}</h3>
      </div>
      <div className={classes.showSecondPartyCont}>
        <PaginatedEntityDropdown
          value={selectedProfile}
          onChange={(value) => {
            updateSecondParty(value);
            setSelectedProfile(value);
          }}
          isMulti={false}
          disabled={needToMakeDisable}
          financialRelationType={tx?.type === "Bill" ? "Vendor" : "Customer"}
          orgtype={tx?.type === "Bill" ? "Organization" : "User"}
          entity={tx?.firstParty?.parentModelName}
          curEntityId={tx?.firstParty?.parent?._id}
          givenProfileIdArr={[]}
        />
      </div>
    </>
  );
}
