import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import BillTemplateOtherSetting from './BillTemplateOtherSetting';
import BillTemplatePaymentSetting from './BillTemplatePaymentSetting';
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  page1: {
    width: "100%",
    height: "100%",
  },
  page2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    zIndex: "1000",
    backgroundColor: "white"
  },
  loaderCont: {
    position: "fixed",
    top: "0px",
    right: "0px",
    width: "100vw",
    height: "100vh",
    zIndex: "9999999999",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  wrongCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.fail
  }
}));

export default function InvoiceTemplateEdit({
  txTemplate, setTxTemplate, walletId, chartAccounts,
  setTemplateNosArr, setChartAccounts, offerings,
  setOfferings, templateNosArr, maxNo, setMaxNo,
  bankChartAccounts, setBankChartAccounts, needToMakeDisable,
  activeStage, needToMakeStatusDisable
}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [openPayment, setOpenPayment] = useState(false);
  const [loadingBool, setLoadingBool] = useState(false);


  return (
    <div className={classes.root} >
      <div className={classes.page1} >
        {walletId === txTemplate?.firstPartyWallet._id ? (
          <BillTemplateOtherSetting
            txTemplate={txTemplate}
            setTxTemplate={setTxTemplate}
            walletId={walletId}
            loadingBool={loadingBool}
            chartAccounts={chartAccounts}
            setChartAccounts={setChartAccounts}
            offerings={offerings}
            setOfferings={setOfferings}
            templateNosArr={templateNosArr}
            setTemplateNosArr={setTemplateNosArr}
            maxNo={maxNo}
            needToMakeDisable={needToMakeDisable}
            setMaxNo={setMaxNo}
            openPayment={openPayment}
            setOpenPayment={setOpenPayment}
            setLoadingBool={setLoadingBool}
          />
        ) : (
          <div className={classes.wrongCont} >Don't have any access</div>
        )}
      </div>
      <Slide direction="left" in={openPayment} mountOnEnter unmountOnExit>
        <div className={classes.page2} >
          {walletId === txTemplate?.firstPartyWallet?._id ? (
            <BillTemplatePaymentSetting
              txTemplate={txTemplate}
              setTxTemplate={setTxTemplate}
              walletId={walletId}
              openPayment={openPayment}
              setOpenPayment={setOpenPayment}
              setLoadingBool={setLoadingBool}
              templateNosArr={templateNosArr}
              setTemplateNosArr={setTemplateNosArr}
              maxNo={maxNo}
              setMaxNo={setMaxNo}
              bankChartAccounts={bankChartAccounts}
              setBankChartAccounts={setBankChartAccounts}
              needToMakeDisable={needToMakeDisable}
              needToMakeStatusDisable={needToMakeStatusDisable}
              activeStage={activeStage}
            />
          ) : (
            <div className={classes.wrongCont} >Don't have any access</div>
          )}
        </div>
      </Slide>
      {loadingBool &&
        <div className={classes.loaderCont} >
          <CircularProgress
            size={60}
            thickness={3}
            style={{ color: 'rgb(92, 144, 242)' }}
          />
        </div>}
    </div>
  );
}