import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountingPrivate from "./AccountingPrivate";
import Cookies from "universal-cookie";

const AccountingRoute = ({ Component, noTopBar, header, curOption, curSubOption, path, ...rest }) => {
    const cookies = new Cookies(null, { path: "/", domain: ".reallist.ai" });
    const { user } = useSelector((state) => state.auth);
    const userCookie = cookies.get("reallistUser");
    const CookieUserObj = typeof userCookie === 'object' ? userCookie : JSON.parse(userCookie || "null");

    return (
        <Route
            key={path}
            path={path}
            {...rest}
            render={(props) => ((CookieUserObj && CookieUserObj._id) || user) ? (
                <AccountingPrivate
                    curOption={curOption}
                    noTopBar={noTopBar}
                    header={header}
                    curSubOption={curSubOption}
                >
                    <Component
                        key={path}
                        {...props}
                    />
                </AccountingPrivate>
            ) : (
                <Redirect
                    to={{ pathname: "/about", state: { from: props.location } }}
                />
            )
            }
        />
    );
};
export default AccountingRoute;