import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TextField, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TxSecondParty from '../commonComponent/transaction/secondParty';
import TxBillListEdit from '../commonComponent/transaction/tx.bill.list.edit';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DialogBillNo from '../expenseAndBills/bills/DialogBillNo';
import { useDebounce } from "react-use";
import { handleTxReject, submitMultipleTransaction } from '../commonComponent/transaction/api';
import TransactionSubmitComp from './TransactionSubmitComp';
import AttachmentsHandler from '../../styled/CommonComponents/Attachments.Handler';
import { allStatusOptions } from "../../../helpers/allStatuses";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import { MdOutlineForkRight } from "react-icons/md";
import LockIcon from '@material-ui/icons/Lock';
import { ArrowDropDown } from "@material-ui/icons";
import { getTx } from "../commonComponent/payBackTx/api"
import ApprovalActionDrawer from "../../approval/ApprovalActionDrawer";
import ApprovalAndActions from "../../approval/ApprovalAndActions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    editIconSty: {
        fontSize: "25px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px",
        }
    },
    invoiceEditBody: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 25px 100px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px 50px",
        }
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        paddingRight: "20px"
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    nextIcon: {
        transform: 'rotate(180deg)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    sectionTop: {
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        padding: "5px",
        marginTop: "50px",
        "& h3": {
            fontSize: "21px",
            fontWeight: "600",
            color: "#193B56"
        },
        [theme.breakpoints.down('sm')]: {
            "& h3": {
                fontSize: "18px",
                fontWeight: "600",
            },
        }
    },
    noBorder: {
        border: "none",
    },
    input: {
        backgroundColor: "#ebebeb",
        padding: "10px"
    },
    memoStyle: {
        width: "100%"
    },
    emptyCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            height: "250px",
            width: "auto",
            marginBottom: "10px"
        },
        "& p": {
            fontSize: "14px",
            color: "gray"
        }
    }
}));

export default function DebitNoteOtherSettings({
    tx, setTx, updateApi, walletId, setOpenPayment, setLoadingBool,
    discounts, setDiscounts, taxes, setTaxes, chartAccounts, loadingBool,
    setChartAccounts, offerings, setOfferings, terms, setTerms,
    txNosArr, setTxNosArr, maxNo, setMaxNo, incomeChartAccounts,
    setIncomeChartAccounts, expenseChartAccounts, setExpenseChartAccounts,
    bankChartAccounts, setBankChartAccounts, needToMakeDisable, activeStage,
    needToMakeStatusDisable
}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { user } = useSelector((state) => state.auth);

    const [memoText, setMemoText] = useState(tx?.memo)
    const [initialDStopper2, setInitialDStopper2] = useState(false)
    const [open, setOpen] = useState(false);
    const oldlateFeeApplicable = tx?.lateFeeApplicable || false;
    const [showGL, setShowGL] = useState(oldlateFeeApplicable);
    const [submitionFailMsg, setSubmitionFailMsg] = useState("")
    const [openErr, setOpenErr] = useState(false)
    const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
    const [openAction, setOpenAction] = useState(false);
    const [actionStatus, setActionStatus] = useState(null)

    // to update transaction
    const update = async (obj) => {
        setLoadingBool(true)
        await updateApi(obj)
        setTx({
            ...tx,
            ...obj
        })
        setLoadingBool(false)
    }

    useDebounce(() => {
        if (initialDStopper2 && tx?.memo !== memoText) {
            update({
                _id: tx?._id,
                memo: memoText
            })
        } else {
            setInitialDStopper2(true)
        }
    }, 1000, [memoText]);

    // to submit transaction only
    const txSubmit = async () => {
        setLoadingBool(true);
        const tx_id = tx?._id;
        await submitMultipleTransaction({
            txIdPurposeObj: {
                [tx_id]: actionStatus,
            },
        })
            .then((data) => {
                console.log("data");
            })
            .catch((err) => {
                console.log(err);
            });
        setLoadingBool(false);
    };

    // to mark a transaction as paid
    const markAsPaid = async () => {
        setLoadingBool(true);
        const tx_id = tx?._id;
        await submitMultipleTransaction({
            txIdPurposeObj: {
                [tx_id]: actionStatus,
            },
        })
            .then((data) => {
                let updatedTx = {
                    ...tx,
                    status: actionStatus,
                    processed: true,
                };
                setTx(updatedTx);
                setLoadingBool(false);
                setOpenPayment(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updateStatus = (statusVal) => {
        if (!needToMakeStatusDisable) {
            let taxItemBool = false;
            let isItemMissChart = false;

            let items = tx?.billList?.items;
            if (!tx?.secondParty || !tx?.secondPartyWallet) {
                setSubmitionFailMsg("Customer is missing! Please select a customer");
                setOpenErr(true);
                return;
            }

            items.length > 0 && items.map((item) => {
                if (item?.tax) {
                    taxItemBool = true;
                }

                if (!item?.chartAccount?._id) {
                    isItemMissChart = true;
                }
            });

            if (!tx?.notIntregratedAccounting && isItemMissChart) {
                setSubmitionFailMsg("item Chart Account is missing! Please select category in item carefully");
                setOpenErr(true);
                return;
            }

            if (!tx?.notIntregratedAccounting && tx?.billList?.tax?.enabled && taxItemBool && !tx?.billList?.taxRelationModel?.chartAccount?._id) {
                setSubmitionFailMsg("Tax Chart Account is missing! Please select a tax");
                setOpenErr(true);
                return;
            }

            if (!tx?.notIntregratedAccounting && tx?.billList?.discount?.enabled && !tx?.billList?.discountRelationModel?.chartAccount?._id) {
                setSubmitionFailMsg("Discount Chart Account is missing! Please select a discount");
                setOpenErr(true);
                return;
            }

            if (!tx?.notIntregratedAccounting && !tx?.BankChartAccount?._id) {
                setSubmitionFailMsg("Please Choose the bank account where the amount will added after the payment done");
                setOpenErr(true);
                return;
            }

            if (!tx?.notIntregratedAccounting && !tx?.paymentBy && (statusVal === "Partially Paid" || statusVal === "Paid in Full")) {
                setSubmitionFailMsg("Please select a payment accept type!!");
                setOpenErr(true);
                return;
            }

            setActionStatus(statusVal)
            setOpenAction(true)
        }
    }

    const handleReject = async () => {
        await handleTxReject({ txId: tx?._id })
            .then((data) => {
                console.log(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getFreshData = async () => {
        await getTx(tx?._id)
            .then((data) => {
                console.log(data)
                setTx(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className={classes.root}>
            <div className={classes.topBar} >
                <div className={classes.barLeft} >
                    <IconButton
                        className={classes.iconButtonStyle}
                        onClick={() => { history.goBack() }}
                    >
                        <KeyboardBackspaceIcon
                            className={classes.backArrow}
                        />
                    </IconButton>
                    <Typography className={classes.headerTitle} >
                        <>Debit Note</><span style={{ marginLeft: "10px" }} >#{tx?.txNo}</span>
                    </Typography>
                    {!needToMakeDisable && (<>
                        <IconButton
                            color="primary"
                            onClick={() => { setOpen(true) }}
                        >
                            <EditIcon
                                className={classes.editIconSty}
                            />
                        </IconButton>
                        <DialogBillNo
                            open={open}
                            handleClose={() => {
                                setOpen(false);
                            }}
                            tx={tx}
                            setTx={setTx}
                            type={"DebitNote"}
                            maxNo={maxNo}
                            setMaxNo={setMaxNo}
                            walletId={walletId}
                            setLoadingBool={setLoadingBool}
                            txNos={txNosArr}
                        />
                    </>)}
                </div>
                <div className={classes.barRight}>
                    <Button
                        id="demo-customized-button"
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => { setOpenApprovalFlow(true) }}
                        endIcon={<MdOutlineForkRight />}
                    >
                        Approval Flow
                    </Button>
                    <ApprovalAndActions
                        openApprovalFlow={openApprovalFlow}
                        setOpenApprovalFlow={setOpenApprovalFlow}
                        loadingBool={loadingBool}
                        setLoadingBool={setLoadingBool}
                        parent={tx?._id}
                        parentModelName={"Transaction"}
                        requestId={tx?.approvalRequest?._id}
                        needToMakeDisable={needToMakeStatusDisable}
                        activeStage={activeStage}
                        origin={"debitNote"}
                        templateId={tx?.approvalRequest?.approvalTemplate?._id}
                        organizationId={tx?.firstParty?.parentModelName === "Organization" ? tx?.firstParty?.parent?._id : null}
                        projectId={tx?.firstParty?.parentModelName === "Project" ? tx?.firstParty?.parent?._id : null}
                    />
                    <div style={{ width: "10px" }}></div>
                    {needToMakeStatusDisable ? (
                        <Button
                            id="demo-customized-button"
                            variant="outlined"
                            size="small"
                            color="primary"
                            disabled
                            endIcon={<LockIcon />}
                        >
                            {tx?.status ? tx?.status : "STATUS"}
                        </Button>
                    ) : (<>
                        <MyPopOver
                            closeOnClick={true}
                            appearContent={
                                <Button
                                    id="demo-customized-button"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    endIcon={<ArrowDropDown />}
                                >
                                    {tx?.status ? tx?.status : "STATUS"}
                                </Button>
                            }
                            showContent={<div className={classes.optionsCont} >
                                {allStatusOptions[tx?.notIntregratedAccounting ? "Debit/Credit Note" : "Transaction"][tx?.status] && allStatusOptions[tx?.notIntregratedAccounting ? "Debit/Credit Note" : "Transaction"][tx?.status].map((statusOption, i) => (
                                    <div
                                        key={i}
                                        className={classes.typeSingleOptionSty}
                                        onClick={() => { updateStatus(statusOption) }}
                                    >
                                        {statusOption}
                                    </div>
                                ))}
                            </div>}
                        />
                        <ApprovalActionDrawer
                            openAction={openAction}
                            setOpenAction={setOpenAction}
                            loadingBool={loadingBool}
                            setLoadingBool={setLoadingBool}
                            actionType={"takeAction"}
                            parent={tx?._id}
                            origin={"debitNote"}
                            parentModelName={"Transaction"}
                            statusField={"status"}
                            statusValue={actionStatus}
                            requestId={tx?.approvalRequest?._id}
                            templateId={tx?.approvalRequest?.approvalTemplate?._id}
                            organizationId={tx?.firstParty?.parentModelName === "Organization" ? tx?.firstParty?.parent?._id : null}
                            projectId={tx?.firstParty?.parentModelName === "Project" ? tx?.firstParty?.parent?._id : null}
                            activeStage={activeStage}
                            actionCallBack={async (type, curStatues) => {
                                if (type === "ApprovalForNextStage") {
                                    if (!tx?.notIntregratedAccounting && (curStatues === "Submitted" || curStatues === "Under Review" || curStatues === "Approved")) {
                                        await txSubmit()
                                    } else if (!tx?.notIntregratedAccounting && (curStatues === "Partially Paid" || curStatues === "Paid")) {
                                        await markAsPaid()
                                    } else if (curStatues === "Draft") {
                                        await handleReject()
                                    }
                                } else if (type === "RequestRejected") {
                                    await handleReject()
                                }
                                await getFreshData()
                            }}
                            noNeedToUpdateParentStatus={!tx?.notIntregratedAccounting}
                        />
                    </>)}
                </div>
            </div>
            <div className={classes.invoiceEditBody} >
                <TxSecondParty
                    tx={tx}
                    setTx={setTx}
                    updateApi={updateApi}
                    relationType={"Customer"}
                    walletId={walletId}
                    needToMakeDisable={needToMakeDisable}
                    setLoadingBool={setLoadingBool}
                />
                <TxBillListEdit
                    tx={tx}
                    setTx={setTx}
                    updateApi={updateApi}
                    showGL={showGL}
                    setLoadingBool={setLoadingBool}
                    discounts={discounts}
                    setDiscounts={setDiscounts}
                    taxes={taxes}
                    incomeChartAccounts={incomeChartAccounts}
                    setIncomeChartAccounts={setIncomeChartAccounts}
                    expenseChartAccounts={expenseChartAccounts}
                    setExpenseChartAccounts={setExpenseChartAccounts}
                    setTaxes={setTaxes}
                    chartAccounts={chartAccounts}
                    setChartAccounts={setChartAccounts}
                    offerings={offerings}
                    setOfferings={setOfferings}
                    needToMakeDisable={needToMakeDisable}
                />
                <div className={classes.sectionTop} style={{ marginTop: "15px" }} >
                    <h3>Note</h3>
                </div>
                <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    placeholder="Enter Memo"
                    InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        className: classes.input
                    }}
                    multiline
                    rows={6}
                    disabled={needToMakeDisable}
                    value={memoText}
                    onChange={(e) => { setMemoText(e.target.value) }}
                    className={classes.memoStyle}
                />
                {tx?._id && (
                    <AttachmentsHandler
                        parentData={tx}
                        setParentData={setTx}
                        parentModel={"Transaction"}
                        needToMakeDisable={needToMakeDisable}
                        setLoadingBool={setLoadingBool}
                    />
                )}
                {!tx?.notIntregratedAccounting && (
                    <TransactionSubmitComp
                        tx={tx}
                        setTx={setTx}
                        updateApi={updateApi}
                        walletId={walletId}
                        setLoadingBool={setLoadingBool}
                        bankChartAccounts={bankChartAccounts}
                        setBankChartAccounts={setBankChartAccounts}
                        submitionFailMsg={submitionFailMsg}
                        setSubmitionFailMsg={setSubmitionFailMsg}
                        openErr={openErr}
                        needToMakeDisable={needToMakeDisable}
                        setOpenErr={setOpenErr}
                    />
                )}
            </div>
        </div>
    );
}