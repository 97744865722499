import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  Button,
  Typography,
  Collapse,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { ExpandMore, ExpandLess, Search } from "@mui/icons-material";
import DrawerContainer from "../styled/generic/DrawerContainer";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../helpers/Api";

const GeneralImportComponent = ({
  isOpen,
  handleClose,
  setIsOpen,
  title = "hello",
  onImport,
  importConfig,
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [expandedItem, setExpandedItem] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [expandeditems, setExpandeditems] = useState([]);
  const [expandedItemsLoading, setExpandedItemsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchOrgUserProject = async () => {
    let response = await Api.post("get/users/organizations/projects/by-user", {
      userProfileId: user.profile,
    });
    const data = response.data;
    if (data) {
      setOrganizations(data.Organization || []);
      setProjects(data.Project || []);
      setUsers(data.User || []);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error while fetching organization and projects",
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrgUserProject();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedItems([]);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleItemToggle = (item) => {
    setSelectedItems((prev) => {
      const currentIndex = prev.findIndex((i) => i._id === item?._id);
      if (currentIndex === -1) {
        return [...prev, item];
      } else {
        return prev.filter((_, index) => index !== currentIndex);
      }
    });
  };

  console.log("selectedItems", selectedItems);
  const fetchBillingAccounts = async (itemId) => {
    const data = await Api.post(
      "wallet/billing/billing-account/sharedIds/get",
      {
        sharedIds: [itemId],
      }
    );
    if (data) {
      setExpandeditems(data?.billingAccounts);
    } else {
      setExpandeditems([]);
    }
    setExpandedItemsLoading(false);
  };

  const handleExpand = async (itemId) => {
    setExpandedItemsLoading(true);
    setExpandeditems([]);
    fetchBillingAccounts(itemId);
    setExpandedItem((prev) => (prev === itemId ? [] : itemId));
  };

  const handleNext = async () => {
    onImport(selectedItems);
    handleClose();
    setActiveTab(0);
    setSelectedItems([]);
    setExpandedItem();
    return;
  };

  return (
    <DrawerContainer
      onClose={handleClose}
      setOpen={setIsOpen}
      open={isOpen}
      title={title}
    >
      <Box
        sx={{
          maxWidth: "480px",
          minWidth: "480px",
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          {importConfig.categories.map((category, index) => (
            <Tab
              key={index}
              icon={category.icon}
              label={
                <Typography variant="caption">{category.label}</Typography>
              }
              sx={{
                "& .MuiTab-iconWrapper": {
                  fontSize: "1.5rem",
                  marginBottom: "4px",
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Enter name or username"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <Search color="action" sx={{ mr: 1 }} />,
          }}
        />
      </Box>

      {loading ? (
        <Box
          sx={{
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {activeTab === 0 &&
            projects.map((item) => (
              <React.Fragment key={item?._id}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleExpand(item?._id)}
                    >
                      {expandedItem === item?._id ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  }
                  sx={{ py: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    {item?.parent?.displayPicture?.url ? (
                      <Avatar
                        src={item?.parent?.displayPicture?.url}
                        alt={item?.parent?.displayName}
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <> {importConfig.categories[activeTab].icon}</>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.parent?.displayName}
                    secondary={item?.parent?.email}
                    primaryTypographyProps={{ variant: "body1" }}
                    secondaryTypographyProps={{ variant: "caption" }}
                  />
                </ListItem>
                <Collapse
                  in={expandedItem === item?._id}
                  timeout="auto"
                  unmountOnExit
                >
                  {expandedItemsLoading ? (
                    <Box
                      sx={{
                        backgroundColor: "Background",
                        width: "90%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={"20px"} />
                    </Box>
                  ) : expandeditems.length > 0 ? (
                    expandeditems.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 1,
                          pl: 3,
                          pr: 2,
                          pb: 2,
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        <Checkbox
                          edge="start"
                          checked={selectedItems.some(
                            (i) => i._id === item?._id
                          )}
                          onChange={() => handleItemToggle(item)}
                          sx={{ padding: 0, mr: 1.5, p: "3px" }}
                          size="small"
                        />
                        {importConfig.categories[activeTab].icon}
                        <Box
                          sx={{
                            ml: 2,
                          }}
                        >
                          <Typography variant="body1" fontWeight={500}>
                            {item?.name}
                          </Typography>
                          <Typography variant="body2">
                            {item?.phone || item?.email}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: "Background",
                        width: "100%",
                        height: "400px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        No billing account found
                      </Typography>
                    </Box>
                  )}
                </Collapse>
              </React.Fragment>
            ))}

          {activeTab === 1 &&
            users.map((item) => (
              <React.Fragment key={item?._id}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleExpand(item?._id)}
                    >
                      {expandedItem === item?._id ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  }
                  sx={{ py: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    {item?.parent?.displayPicture?.url ? (
                      <Avatar
                        src={item?.parent?.displayPicture?.url}
                        alt={item?.parent?.displayName}
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <> {importConfig.categories[activeTab].icon}</>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.parent?.displayName}
                    secondary={item?.parent?.email}
                    primaryTypographyProps={{ variant: "body1" }}
                    secondaryTypographyProps={{ variant: "caption" }}
                  />
                </ListItem>
                <Collapse
                  in={expandedItem === item?._id}
                  timeout="auto"
                  unmountOnExit
                >
                  {expandedItemsLoading ? (
                    <Box
                      sx={{
                        backgroundColor: "Background",
                        width: "90%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={"20px"} />
                    </Box>
                  ) : expandeditems.length > 0 ? (
                    expandeditems.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 1,
                          pl: 3,
                          pr: 2,
                          pb: 2,
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        <Checkbox
                          edge="start"
                          checked={selectedItems.some(
                            (i) => i._id === item?._id
                          )}
                          onChange={() => handleItemToggle(item)}
                          sx={{ padding: 0, mr: 1.5, p: "3px" }}
                          size="small"
                        />
                        {importConfig.categories[activeTab].icon}
                        <Box
                          sx={{
                            ml: 2,
                          }}
                        >
                          <Typography variant="body1" fontWeight={500}>
                            {item?.name}
                          </Typography>
                          <Typography variant="body2">
                            {item?.phone || item?.email}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: "Background",
                        width: "100%",
                        height: "400px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        No billing account found
                      </Typography>
                    </Box>
                  )}
                </Collapse>
              </React.Fragment>
            ))}
          {activeTab === 2 &&
            organizations.map((item) => (
              <React.Fragment key={item?._id}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleExpand(item?._id)}
                    >
                      {expandedItem === item?._id ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  }
                  sx={{ py: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    {item?.parent?.displayPicture?.url ? (
                      <Avatar
                        src={item?.parent?.displayPicture?.url}
                        alt={item?.parent?.displayName}
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <> {importConfig.categories[activeTab].icon}</>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.parent?.displayName}
                    secondary={item?.parent?.primaryEmail}
                    primaryTypographyProps={{ variant: "body1" }}
                    secondaryTypographyProps={{ variant: "caption" }}
                  />
                </ListItem>
                <Collapse
                  in={expandedItem === item?._id}
                  timeout="auto"
                  unmountOnExit
                >
                  {expandedItemsLoading ? (
                    <Box
                      sx={{
                        backgroundColor: "Background",
                        width: "90%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={"20px"} />
                    </Box>
                  ) : expandeditems.length > 0 ? (
                    expandeditems.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 1,
                          pl: 3,
                          pr: 2,
                          pb: 2,
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        <Checkbox
                          edge="start"
                          checked={selectedItems.some(
                            (i) => i._id === item?._id
                          )}
                          onChange={() => handleItemToggle(item)}
                          sx={{ padding: 0, mr: 1.5, p: "3px" }}
                          size="small"
                        />
                        {importConfig.categories[activeTab].icon}
                        <Box
                          sx={{
                            ml: 2,
                          }}
                        >
                          <Typography variant="body1" fontWeight={500}>
                            {item?.name}
                          </Typography>
                          <Typography variant="body2">
                            {item?.phone || item?.email}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: "Background",
                        width: "90%",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body1">
                        No billing account found
                      </Typography>
                    </Box>
                  )}
                </Collapse>
              </React.Fragment>
            ))}
        </List>
      )}

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          bgcolor: "background.paper",
          p: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={selectedItems?.length === 0}
          onClick={handleNext}
        >
          NEXT
        </Button>
      </Box>
    </DrawerContainer>
  );
};

export default GeneralImportComponent;
